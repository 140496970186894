<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>ユーザー編集</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>ユーザー名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors['nameSei'] }"
                                type="text"
                                name="nameMei"
                                v-trim
                                v-maxlength
                                v-space
                                maxlength="20"
                                placeholder="姓"
                                v-model="formData.nameSei"
                              />
                            </div>
                            <div class="form-group-item">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors['nameMei'] }"
                                type="text"
                                name="nameMei"
                                v-trim
                                v-maxlength
                                v-space
                                maxlength="20"
                                placeholder="名"
                                v-model="formData.nameMei"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>メールアドレス / ID</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-6">
                              <input
                                class="form-control"
                                :class="{ 'is-error': errors['email'] }"
                                type="text"
                                name="email"
                                v-email
                                v-trim
                                v-maxlength
                                maxlength="50"
                                v-model="formData.email"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow>
                      <template v-slot:label>初回パスワード</template>
                      <template v-slot:labelNote><span class="text-note">（8文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ）</span></template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-6">
                              <div class="form-password">
                                <input
                                  class="form-control form-password-input"
                                  :class="{ 'is-error': errors['password'] }"
                                  :type="pwdVisible ? 'text' : 'password'"
                                  name="password"
                                  v-trim
                                  v-model="formData.password"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" value="" v-model="pwdVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <UserMailSetting :formData="formData" @updateData="updateData"/>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <TableActivites :activities="userActivities" />
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" type="button" @click="showModal('deletedModal')">削除</button>
          </li>
          <li class="listGrid-item">
            <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ userDetail.status ? '一時停止' : '再開' }}</button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'SettingUser' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-blue" :handle-submit="register" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ userDetail.status ? '一時停止' : '再開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            userDetail.status
              ? `このユーザーを${appName}にログインできないようにしますか？`
              : `このユーザーを${appName}にログインできるようにしますか？`
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="userDetail.status ? '一時停止にする' : '再開する'"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">対象のアカウントを削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('deletedModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="deleteUser" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixins
import nav from '@/mixins/nav/setting';
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
import edit from '@/mixins/plugin/edit';
import { isAlphanumSpecialWithMinLength, notSpace } from '@/helpers/validators';
//component
import FormRow from '@/components/FormRow.vue';
import UserMailSetting from '@/components/UserMailSetting.vue';
import userMailSetting from '@/mixins/module/userMailSetting';
import TableActivites from '@/components/TableActivites.vue';
//modal
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';
//constanst
import { RoleConstants } from '@/constants/role';

export default {
  name: 'SettingUserEditMaster',
  data: function() {
    return {
      pageName: '設定',
      formData: {
        email: '',
        nameSei: '',
        nameMei: '',
        password: '',
        authEmailFlag: 1,
        receptionEmailFlag: 0,
      },
      storeModule: 'settingUser',
      pause: false,
      pauseModal: false,
      deletedModal: false,
      appName: process.env.VUE_APP_APP_NAME,
    };
  },
  mixins: [nav, password, error, edit, modal, userMailSetting],
  components: {
    FormRow,
    Modal,
    UserMailSetting,
    TableActivites,
  },
  validations() {
    let formData = {
      nameSei: { required, maxLength: maxLength(20), notSpace },
      nameMei: { required, maxLength: maxLength(20), notSpace },
    };
    if (this.formData.password !== '') {
      formData = {
        ...formData,
        password: { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(8) },
      };
    }
    return { formData };
  },
  computed: {
    ...mapGetters({
      userDetail: 'settingUser/userDetail',
    }),
    id: function() {
      return this.$route.params.id;
    },
  },
  methods: {
    register: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formData, 'user');
      } else {
        let data = {
          email: this.formData.email,
          password: this.formData.password,
          nameSei: this.formData.nameSei,
          nameMei: this.formData.nameMei,
          receptionEmailFlag: this.formData.receptionEmailFlag ? 1 : 0,
          authEmailFlag: this.formData.authEmailFlag ? 1 : 0,
        };
        if (this.formData.password === '') delete data.password;
        const result = await this.$store.dispatch('settingUser/updateUser', {
          data: data,
          id: this.id,
          kind: RoleConstants.ROLE_GMO,
        });
        if (result) {
          await this.$router.push({ name: 'SettingUser' });
          this.$message.updated('accountUserInfo');
        }
      }
    },
    async deleteUser() {
      const result = await this.$store.dispatch('settingUser/deleteUser', this.id);
      if (result) {
        await this.$router.push({ name: 'SettingUser' });
        this.$message.deleted('accountUser');
      }
    },
    async changeStatus() {
      const result = await this.$store.dispatch('settingUser/updateStatus', { status: !this.userDetail.status ? 1 : 0 });
      this.closeModal('pauseModal');
      if (result) {
        this.$store.dispatch('settingUser/getUserDetail', this.id);
        if (this.userDetail.status) this.$message.showSuccess('reactiveUser');
        else this.$message.showSuccess('repauseUser');
      }
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    await this.$store.dispatch('settingUser/getUserDetail', this.id);
    this.formData = { ...this.formData, ...this.userDetail };
    this.$loading.clear(loading);
  },
};
</script>
